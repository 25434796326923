import React from 'react';
import LandingPage from './LandingPage';
import TechReportPage from './TechReportPage';

import { Route, Routes } from 'react-router-dom';
const AppRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={<LandingPage />}
      />
      <Route
        path='/swe-bench'
        element={<TechReportPage />}
      />
      <Route
        path='*'
        element={<div>404 - Page Not Found</div>}
      />
    </Routes>
  );
};
export default AppRouter;
