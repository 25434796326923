import React from 'react';
import { ArrowRight, Search, Clock, Database, CheckCircle } from 'lucide-react';
import './App.css';

const Product = ({ title, description, features, ctaText, ctaLink }) => (
  <div className='product'>
    <h2>{title}</h2>
    <p>{description}</p>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>
          <ArrowRight size={16} />
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={() => {
        if (ctaLink.startsWith('http')) {
          window.open(ctaLink, '_blank');
        } else {
          window.location.href = ctaLink;
        }
      }}
      className='cta-button'
    >
      {ctaText}
    </button>
  </div>
);

function LandingPage() {
  const handleJoinWaitlist = () => {
    window.open('https://nfactorialschool.typeform.com/to/BRGrpuZd', '_blank');
  };

  return (
    <div className='App'>
      <header>
        <h1>nFactorial AI</h1>
        <p className='subtitle'>
          Empowering Decision-Making with AI-Driven Insights
        </p>
      </header>

      <main>
        <section className='hero'>
          <h2>Unlock the Power of AI for Your Business</h2>
          <p>
            Harness cutting-edge AI technology to gain valuable insights and
            make informed decisions.
          </p>
          <button
            className='waitlist-button'
            onClick={handleJoinWaitlist}
          >
            Request Access
          </button>
        </section>

        <section className='products'>
          <Product
            title='AI-Powered Tools by research.nfactorial.dev'
            description='Revolutionize your processes with our comprehensive AI-powered tools for investors and HR professionals. Simply upload your files and let our personal AI assistant browse and answer questions from 1000+ documents.'
            features={[
              'YC S24 Investor Tool: 240+ parsed company profiles, quick answers to specific queries, in-depth "pro" answers for comprehensive insights, data from YC website, LinkedIn, company homepages, and more.',
              'AI-Powered HR Agent: Analyze Excel spreadsheets and PDF files, browse and evaluate candidates efficiently, select the best candidates based on your criteria, and answer complex HR-related questions.',
            ]}
            ctaText='Explore Our Tools'
            ctaLink='https://research.nfactorial.dev'
          />
          <Product
            title='SWE-Bench AI Agent by nFactorial AI'
            description='An autonomous benchmark tool designed to evaluate AI models on software engineering tasks. Built according to SWE-Bench standards, this agent optimizes bug localization, code fixing, and analysis while minimizing hallucinations and token usage. Perfect for research in AI-driven software development.'
            features={[
              'AI-driven software issue localization and patch generation',
              'Error reduction via structured workflow and brute-force validation',
              'Optimized for performance evaluation within standard benchmarks',
            ]}
            ctaText='Access the Technical Report'
            ctaLink='swe-bench'
            secondaryCtaText='Learn More about SWE-Bench'
            secondaryCtaLink='https://www.swebench.com/'
          />
        </section>

        {/* <section className='features'>
          <div className='feature'>
            <Search size={48} />
            <h3>Intelligent Search</h3>
            <p>
              Quickly find relevant information with our advanced search
              capabilities.
            </p>
          </div>
          <div className='feature'>
            <Clock size={48} />
            <h3>Time-Saving</h3>
            <p>
              Get instant answers and save hours of manual research and
              analysis.
            </p>
          </div>
          <div className='feature'>
            <Database size={48} />
            <h3>Comprehensive Data</h3>
            <p>Access a wealth of information from various reliable sources.</p>
          </div>
          <div className='feature'>
            <CheckCircle size={48} />
            <h3>Accurate Insights</h3>
            <p>
              Benefit from AI-driven analysis for more informed decision-making.
            </p>
          </div>
        </section> */}
      </main>

      <footer className='footer'>
        Powered by <strong>nFactorial AI</strong>
        <div style={{ marginTop: '10px' }}>nFactorial, Inc.</div>
      </footer>
    </div>
  );
}

export default LandingPage;
